<template>
  <ul class="eventslist row">
    <EventsItem
      :event="event"
      v-for="event in events.slice(0, 3)"
      :key="event.event.id"
      class="eventslist__event col-12 col-md-4"
    />
  </ul>
</template>

<script>
import EventsItem from '@/components/EventsItem.vue';

export default {
  name: 'EventsList',
  props: {
    events: { type: Object, required: true },
  },
  components: {
    EventsItem,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.eventslist {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  margin-top: 1rem;
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}
</style>
