<template>
  <li>
    <a :href="event.event.localist_url">
      <div class="eventslist__event-date">
        {{ getDate(event.event.event_instances[0].event_instance.start) }}
      </div>
      <div class="eventslist__event-title">{{ event.event.title }}</div>
      <div
        v-if="!event.event.event_instances[0].event_instance.all_day"
        class="eventslist__event-time"
      >
        {{ getTime(event.event.event_instances[0].event_instance.start) }}
      </div>
    </a>
  </li>
</template>

<script>
import moment from 'moment';

export default {
  name: 'EventsItem',
  props: {
    event: { type: Object, required: true },
  },

  methods: {
    getDate(date) {
      return moment(date, 'YYYY-MM-DD').format('MM.DD');
    },
    getTime(date) {
      return moment(date).format('h:mm a');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@mixin event-text-main {
  @include heading-base;
  font-size: 1rem;
  line-height: 1.125rem;
  letter-spacing: 0em;
  text-align: left;
  color: $white;
}

@mixin event-text-sub {
  font-family: $font-family-sans-serif;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: 1.4375rem;
  letter-spacing: 0em;
  text-align: left;
  color: $white;
}

.eventslist {
  &__event {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-style: solid;
    border-width: 1px 0 0 0;
    border-color: fade-out($white, .5);
    @include media-breakpoint-up(md) {
      // min-height: 250px;
      align-items: center;
      border-width: 0 0 0 1px;
      padding-left: 2rem;
      align-items: flex-start;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }

    &-date {
      margin-bottom: .5rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: 1rem;
      }
    }
    &-date,
    &-title {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include event-text-main();
      @include media-breakpoint-up(lg) {
        font-size: 1.75rem;
        line-height: 1.2;
      }
    }

    &-time {
      @include event-text-sub();
      margin-top: .5rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
        line-height: 2;
        margin-top: 1rem;
      }
    }
  }
}
</style>
