<template>
  <ul class="newslist">
    <NewsItem
      v-for="story in stories"
      :key="story.id"
      :story="story"
      :showSource="showSource"
      data-aos="fade"
      data-aos-offset="100"
    ></NewsItem>
  </ul>
</template>

<script>
import NewsItem from '@/components/NewsItem.vue';

export default {
  name: 'NewsList',
  props: {
    stories: {
      type: Array,
      required: true
    },
    showSource: {
      type: Boolean,
      default: true,
    },
  },
  components: { NewsItem },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .newslist {
    margin: 0;
    padding: 0;
    list-style: none;
  }
</style>
