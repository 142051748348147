<template>
  <VRVideoBackground
    :src="src"
    :poster="poster"
    style="height: 100%; position: relative;"
    ref="videobackground"
  >
    <a v-if="button" id="video__button" @click="togglePlay">
      <i v-if="playing" class="bi bi-pause-circle-fill"></i>
      <i v-else class="bi bi-play-circle-fill"></i>
    </a>
  </VRVideoBackground>
</template>
<script>
import VRVideoBackground from 'vue-responsive-video-background-player';

export default {
  name: 'VideoBackground',
  props: {
    src: { type: String, required: true },
    poster: { type: String, required: false },
    button: { type: Boolean, default: false },
  },
  data() {
    return {
      playing: true,
    };
  },

  methods: {
    togglePlay() {
      this.playing = !this.playing;
      if (this.playing) {
        this.$refs.videobackground.player.play();
      } else {
        this.$refs.videobackground.player.pause();
      }
    },
  },
  components: {
    VRVideoBackground,
  },
};
</script>
<style scoped lang="scss">
i {
  color: $white;
  font-size: 2rem;
}

#video__button {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
</style>
