<template>
  <div class="row g-0">
    <div class="col-12 col-lg-6 bg-primary bg-diagonal--dark text-white">
      <div data-aos="fade" class="p-5">
        <h3 class="section__heading mb-3">{{ title }}</h3>
        <ul id="key-messages" class="key-messages accordion accordion-flush">
          <li
            class="key-messages__message accordion-item"
            v-for="(message, index) in messages"
            :key="message.title"
            :id="$filters.slugify(message.title)"
          >
            <h3
              class="key-messages__message-header accordion-header"
              :id="$filters.slugify(message.title) + `-heading`"
            >
              <button
                class="key-messages__message-button accordion-button"
                :class="{ 'collapsed': index !== 0 }"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#` + $filters.slugify(message.title) + `-collapse`"
                :aria-expanded="false"
                :aria-controls="$filters.slugify(message.title) + `-collapse`"
                @click="changeMessage(message.image)"
              >
                {{ message.title }}
              </button>
            </h3>
            <div
              :id="$filters.slugify(message.title) + `-collapse`"
              class="key-messages__message-collapse accordion-collapse collapse"
              :class="{ 'show': index === 0 }"
              :aria-labelledby="$filters.slugify(message.title) + `-heading`"
              data-bs-parent="#key-messages"
            >
              <div class="key-messages__message-body accordion-body">
                <p>{{ message.summary }}</p>
                <Btn
                  :href="message.link"
                  :color="`white`"
                  :outline="true"
                  size="sm"
                >Read More</Btn>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-lg-6 bg-light bg-diagonal--dark">
      <BackgroundImage :img="this.backgroundImage"></BackgroundImage>
    </div>
  </div>
</template>

<script>
// import {slugify} from '@/mixins/slugify';
import BackgroundImage from '@/components/BackgroundImage.vue';
import Btn from '@/components/Btn.vue';
import { Collapse } from 'bootstrap/js/dist/collapse';

export default {
  name: 'Key-Messages',
  data() {
    return {
      backgroundImage: this.messages[0].image,
    }
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    messages: {
      type: Object,
      required: true,
    }
  },
  components: {
    BackgroundImage,
    Btn,
  },
  methods: {
    changeMessage: function(imageURL) {
      this.backgroundImage = imageURL;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.key-messages {
  list-style: none;
  margin: 0;
  padding: 0;
  &__message {
    padding: 1rem 0 1rem 0;
    border-style: solid;
    border-color:$white;
    border-width: 0 0 1px;
    background: none;
    border-radius: 0;
    &:first-child {
      .key-messages__mesage-content {
        // display: block;
      }
    }
    &:first-of-type,
    &:last-of-type {
      border-radius: 0;
      .key-messages__message-button {
        border-radius: 0;
      }
    }
    &-collapse {
      // display: none;
      // margin: 1rem 0 0 0;
    }
    &-header {
      // display: flex;
      // justify-content: space-between;
      // @include heading-base;
      // font-size: 1.5rem;
      margin: 0;
      // &:after {
      //   // font-family: $font-family-sans-serif;
      //   content: '\21A7';
      //   color: fade-out($white, 0.5);
      // }
    }
    &-button {
      padding-left: 0;
      padding-right: 0;
      @include heading-base;
      font-size: 1.75rem;
      background: transparent;
      color: $white;
      &:after {
        color: $white;
        color: fade-out($white, 0.5);
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        // height: unset;
        // width: unset;
      }
      &:not(.collapsed) {
        color: $white;
        background: transparent;
        box-shadow: none;
        &::after {
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        }
      }
    }
    &-body {
     padding: 0;
     color: $white;
    }
  }
}
</style>
