<template>
  <div class="events row py-5 align-items-center">
    <div class="col-12 col-md-3">
      <div class="events__titleblock">
        <h2 class="events__titleblock-title">{{ heading }}</h2>
        <a class="events__titleblock-link" href="https://calendar.uga.edu">
          <div>View All<span class="visually-hidden"> events on master calendar</span></div>
          <i aria-hidden="true" class="bi bi-chevron-right"></i>
        </a>
      </div>
    </div>
    <div class="col-12 col-md-9">
      <div v-if="errored">
        <p>Something went wrong. Please try again later.</p>
      </div>
      <EventsList
        v-else-if="!loading"
        :events="fetchedEvents"
      />
      <div
        v-else
        class="spinner-border mx-auto"
        style="width: 3rem; height: 3rem;"
        role="status"
      >
        <span class="visually-hidden">Loading&hellip;</span>
      </div>
    </div>
  </div>
</template>

<script>
import EventsList from '@/components/EventsList.vue';
import moment from 'moment';

export default {
  name: 'Events',
  data() {
    return {
      fetchedEvents: [],
      currentTime: Date,
      loading: true,
      errored: false,
    };
  },
  props: {
    heading: { type: String, required: true },
    fetchURL: { type: String, required: true },
  },
  components: {
    EventsList,
  },
  mounted() {
    this.currentTime = moment().format();
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.axios
        .get(`${this.fetchURL}&start=${this.currentTime}`)
        .then((res) => {
          this.fetchedEvents = res.data.events;
        })
        .catch((error) => {
          console.error(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.events {
  &__titleblock {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      padding-left: 3rem;
    }
    &-title {
      @include heading-base;
      font-size: 2rem;
      margin-bottom: 1.5rem;
      @include media-breakpoint-up(lg) {
        font-size: 3rem;
      }
    }
    &-link {
      font-family: $font-family-serif;
      // font-size: 0.9375rem;
      font-weight: $font-weight-bold;
      color: $white;
      display: flex;
      gap: 0.5rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
      & > i {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
