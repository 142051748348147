<template>
  <div v-if="video" class="hero" :class="computedClass">
    <VideoBackground
      :src="video"
      :poster="require('@/assets/img/posters/' + bgImage)"
    >
      <HeroContent :heading="heading" :text="text" :links="links"></HeroContent>
    </VideoBackground>
  </div>
  <div
    v-else
    class="hero"
    :class="computedClass"
    :style="{
      backgroundImage: `url(${require('@/assets/img/posters/' + bgImage)})`,
    }"
  >
    <div class="photobg-content">
      <HeroContent :heading="heading" :text="text" :links="links"></HeroContent>
    </div>
  </div>
</template>

<script>
import HeroContent from "@/components/HeroContent.vue";
import VideoBackground from "vue-responsive-video-background-player";
export default {
  name: "Hero",
  components: {
    VideoBackground,
    HeroContent,
  },
  props: {
    video: {
      type: String,
      default: null,
    },
    bgImage: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
    overlay: {
      type: String,
      default: null,
    },
  },
  computed: {
    /**
     * Adds color prop to classes for Icon Wrapper
     */
    computedClass() {
      let cls = [];
      switch (this.overlay) {
        case "medium":
          cls.push("overlay");
          break;
        case "light":
          cls.push("overlay overlay--light");
          break;
        case "dark":
          cls.push("overlay overlay--dark");
          break;
      }
      return cls;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.hero {
  min-height: 100vh;
  background-color: $black;
  background-size: cover;
  background-position: center center;
  color: $white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  text-shadow: 0 1px 3px fade-out($black, 0.5);
  &.overlay {
    .photobg-content,
    .video-wrapper {
      &::before {
        content: " ";
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 1;
      }
    }
    &--light {
      .photobg-content,
      .video-wrapper {
        &::before {
          background: rgba(0, 0, 0, 0.25);
        }
      }
    }
    &--dark {
      .photobg-content,
      .video-wrapper {
        &::before {
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {
    // min-height: 56.25vw;
    min-height: 100vh;
    // max-height: 100vh;
  }
  .vue-responsive-videobg {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .videobg-content {
      position: relative !important;
      display: flex;
    }
  }
}
</style>
