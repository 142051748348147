<template>
  <div class="home">
    <section id="hero">
      <Hero
        bgImage="2024-09_academic-excellence.jpg"
        heading="<span>No. 18</span><br/>Best Public University"
        :text="
          `<p>The University of Georgia has been a Top 20 public university in the U.S. for nine consecutive years (U.S. News and World Report, 2025).</p>
          <!--<div><a class='btn btn-outline-white' href='https://wearegeorgia.uga.edu/'>Learn More</a></div>-->`
        "
        :video="require('@/assets/video/hph-24-09_academic-excellence.mp4')"
        overlay="dark"
        :links="[
          {
            title: 'Learn About UGA',
            href: '/about',
            description:
              'Discover Georgia’s flagship institution for higher education.',
          },
          {
            title: 'Browse Degrees',
            href: 'https://majors.admissions.uga.edu/',
            description:
              '19 distinct schools and colleges to prepare for your future.',
          },
          {
            title: 'Plan Your Visit',
            href: 'https://visit.uga.edu/',
            description: 'Experience UGA’s historic campus for yourself.',
          },
        ]"
      ></Hero>
    </section>

    <section id="news" class="py-5 bg-prism">
      <div class="container-xl">
        <News
          heading="University News"
          featuredNewsUrl="https://news.uga.edu/wp-json/acf/v3/options/options/featured_post"
          latestNewsUrl="https://news.uga.edu/wp-json/wp/v2/posts?per_page=4&_embed=wp:featuredmedia&_fields=_links.wp:featuredmedia,_embedded,link,title,date,_links&tags_exclude=2158"
          :showSource="true"
        ></News>
      </div>
    </section>
    <section id="keymessages">
      <KeyMessages
        :title="`The University of Georgia is&hellip;`"
        :messages="messages"
        v-on:change-message="onUpdateItem"
      ></KeyMessages>
    </section>
    <section id="imagegrid">
      <ImageGrid :images="images" :figures="figures" :size="'3x3'"></ImageGrid>
    </section>

    <section id="audiences" class="py-5 bg-grey bg-geo bg-geo--dark text-white">
      <div class="container-xl">
        <AudiencePanel data-aos="fade" :audiences="audiences" textured />
      </div>
    </section>
    <section id="events">
      <div class="bg-red text-white">
        <div class="container-fluid">
          <Events
            heading="Events"
            fetchURL="https://calendar.uga.edu/api/2/events?days=60&pp=5&recurring=false"
          ></Events>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import Btn from '@/components/Btn.vue';
import Events from "@/components/Events.vue";
import Hero from "@/components/Hero.vue";
import News from "@/components/News.vue";
import KeyMessages from "@/components/KeyMessages.vue";
// import InfoBanner from '@/components/InfoBanner.vue';
import ImageGrid from "@/components/ImageGrid.vue";
import AudiencePanel from "@/components/AudiencePanel.vue";
// import BackgroundImage from '@/components/BackgroundImage.vue';

export default {
  name: "Home",
  components: {
    // InfoBanner,
    // Btn,
    Events,
    Hero,
    News,
    KeyMessages,
    ImageGrid,
    AudiencePanel,
    // BackgroundImage,
  },
  metaInfo: {
    title: "University of Georgia: Leading Public Higher Education",
    description:
      "As the birthplace of public higher education in America, the University of Georgia is one of the top public research universities in the nation.",
    og: {
      image: require("@/assets/img/homepage/35501-081_edit.jpg"),
      title: "University of Georgia: Leading Public Higher Education",
      description:
        "As the birthplace of public higher education in America, the University of Georgia is one of the top public research universities in the nation.",
    },
  },
  methods: {},
  data() {
    return {
      images: [
        { src: "homepage/37073-051.jpg", position: 1, focus: "bottom" },
        { src: "history/32963-026.jpg", position: 2, focus: "center" },
        {
          src: "homepage/student-walking-stairs.jpg",
          position: 3,
          focus: "center",
        },
        {
          src: "video/research2.mp4",
          position: 4,
          focus: "center",
          video: "true",
        },
      ],
      images2: [
        { src: "img1.png", position: 1, focus: "top" },
        { src: "img4.png", position: 2, focus: "bottom" },
      ],
      figures: [
        {
          prefix: "#",
          endAmount: 9,
          subheading: "Niche, Top Public Universities in the U.S.",
          backgroundColor: "black",
          textColor: "white",
          textured: false,
        },
        {
          prefix: "#",
          endAmount: 13,
          subheading: "Best Value Public College, Princeton Review",
          backgroundColor: "white",
          textColor: "black",
          textured: false,
        },
        {
          prefix: "Top ",
          endAmount: 20,
          subheading: "U.S. News & World Report, Best Public Colleges",
          backgroundColor: "red",
          textColor: "white",
          textured: true,
        },
      ],
      figures2: [
        {
          prefix: "#",
          number: 11,
          subheading: "Niche, Top Public Universities in the U.S.",
          backgroundColor: "grey",
          textColor: "white",
          textured: false,
        },
        {
          prefix: "#",
          number: 6,
          subheading: "Open Doors, Study Abroad (2020)",
          backgroundColor: "black",
          textColor: "white",
          textured: false,
        },
      ],

      audiences: [
        {
          id: "students",
          name: "Current Students",
          image: "homepage/students-walking-fall.jpg",
          links: [
            {
              title: "MyUGA Portal",
              url: "https://my.uga.edu/",
            },
            {
              title: "eLC",
              url: "https://elc.uga.edu/",
            },
            {
              title: "Athena",
              url: "https://athena.uga.edu/",
            },
            {
              title: "Financial Aid",
              url: "https://osfa.uga.edu/",
            },
            {
              title: "Student Account Services",
              url: "https://busfin.uga.edu/bursar/bursar_quick_links/",
            },
            {
              title: "Advising Services",
              url: "https://advising.uga.edu/",
            },
            {
              title: "Registrar",
              url: "https://reg.uga.edu/",
            },
            {
              title: "Academic Calendar",
              url: "https://reg.uga.edu/general-information/calendars/",
            },
            {
              title: "Recreational Sports",
              url: "https://recsports.uga.edu/",
            },
            {
              title: "Well-Being",
              url: "https://well-being.uga.edu/",
            },
          ],
          moreLink: "https://studentaffairs.uga.edu/",
        },
        {
          id: "faculty-staff",
          name: "Faculty & Staff",
          image: "faculty-staff/paige-carmichael-teaching.jpg",
          links: [
            {
              title: "MyUGA Portal",
              url: "https://my.uga.edu/",
            },
            {
              title: "UGA Mail",
              url: "https://ugamail.uga.edu/",
            },
            {
              title: "OneSource",
              url: "https://onesource.uga.edu/",
            },
            {
              title: "Master Calendar",
              url: "https://calendar.uga.edu/",
            },
            {
              title: "OneUSG Connect",
              url: "https://hcm-sso.onehcm.usg.edu/",
            },
            {
              title: "Human Resources",
              url: "https://hr.uga.edu/employees/employment/",
            },
            {
              title: "Learning & Development",
              url: "https://hr.uga.edu/employees/training/",
            },
          ],
          moreLink: "/faculty-and-staff",
        },
        {
          id: "alumni",
          name: "Alumni",
          image: "facts/38885-013_crop.jpg",
          links: [
            {
              title: "Alumni Association",
              url: "https://alumni.uga.edu/",
            },
            {
              title: "Georgia Dogs",
              url: "https://georgiadogs.com/",
            },
            {
              title: "Corporate Engagement",
              url: "https://alumni.uga.edu/corporate/",
            },
            {
              title: "Alumni Career Services",
              url: "https://career.uga.edu/alumni/alumni",
            },
            {
              title: "Alumni Chapters",
              url: "https://alumni.uga.edu/chapters/",
            },
            {
              title: "Make a Gift",
              url: "https://alumni.uga.edu/give/",
            },
          ],
          moreLink: "https://alumni.uga.edu/",
        },
        {
          id: "community",
          name: "Community",
          nameLong: "the Community",
          image: "community/32629-102.jpg",
          links: [
            {
              title: "Office of Community Relations",
              url: "https://govt.relations.uga.edu/about-community-relations/",
            },
            {
              title: "Small Business Development Center",
              url: "https://www.georgiasbdc.org/",
            },
            {
              title: "Youth Programs",
              url: "https://outreach.uga.edu/community-students-and-youth/",
            },
            {
              title: "Athens-Clarke County Extension",
              url: "https://extension.uga.edu/county-offices/clarke.html",
            },
            {
              title: "Georgia Center for Continuing Education & Hotel",
              url: "https://www.georgiacenter.uga.edu/",
            },
            {
              title: "Master Calendar",
              url: "https://calendar.uga.edu/",
            },
          ],
          moreLink: "/community",
        },
      ],
      resources: [
        {
          name: "Undergraduate Admissions",
          link: "https://uga.edu",
          content: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
          name: "Experiential Learning",
          link: "https://uga.edu",
          content: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
          name: "Registrar",
          link: "https://uga.edu",
          content: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
          name: "Office of Research",
          link: "https://uga.edu",
          content: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
          name: "Online Learning",
          link: "https://uga.edu",
          content: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
        },
        {
          name: "Athletics",
          link: "https://uga.edu",
          content: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
        },
      ],
      messages: [
        {
          title: "An Academic Powerhouse",
          summary:
            "At UGA, we are committed to excellence in everything we do. Our internationally recognized faculty, student-centered teaching, and focus on hands-on experiences make us one of the best public universities in the nation.",
          link: "/about/academic-excellence",
          image: "academic-excellence/37560-108.jpg",
        },
        {
          title: "Built Upon Visionary Leadership",
          summary:
            "More than two centuries ago, the University of Georgia was founded on the revolutionary idea of public higher education in America, establishing an enduring legacy of transformative leadership.",
          link: "/about",
          image: "homepage/36963-044.jpg",
        },
        {
          title: "Serving Communities",
          summary:
            "We are committed to partnering with communities to solve our biggest challenges through research, innovation, and education.",
          link: "https://impact.uga.edu/",
          image: "community/36558-077.jpg",
        },
        {
          title: "Driving Discovery and Innovation",
          summary:
            "Through research and innovation, we are determined to promote stronger communities, healthier lifestyles, and a more secure future.",
          link: "/about/research",
          image: "research/35712-036.jpg",
        },
        {
          title: "A Top Value",
          summary:
            "When it comes to educational quality, value, and affordability, UGA consistently ranks as one of the best public universities because of the opportunities we provide in and outside the classroom.",
          link: "/about/value",
          image: "homepage/35864-059.jpg",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
