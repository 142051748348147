<template>
  <div class="news">
    <div class="news__title">
      <h2 class="news__title-header">{{ heading }}</h2>
      <a class="news__title-link" href="https://news.uga.edu/"
        >Read More<i aria-hidden="true" class="bi bi-chevron-compact-right"></i
      ></a>
    </div>
    <div v-if="!errored" class="row gx-5 gy-3">
      <div class="col-lg-6">
        <NewsFeatured
          v-if="featuredPost.length > 0"
          :story="featuredPost[0]"
          :showSource="this.showSource"
          data-aos="fade"
          data-aos-offset="0"
        ></NewsFeatured>
      </div>
      <div class="col-lg-6">
        <NewsList
          v-if="secondaryPosts.length > 0"
          :stories="secondaryPosts"
          :showSource="this.showSource"
        ></NewsList>
      </div>
    </div>
    <section v-else>
      <p>Something went wrong. Please try again later.</p>
    </section>
  </div>
</template>

<script>
import NewsFeatured from '@/components/NewsFeatured.vue';
import NewsList from '@/components/NewsList.vue';

export default {
  name: 'News',
  data() {
    return {
      featuredPost: [],
      secondaryPosts: [],
      loading: true,
      errored: false,
    };
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    featuredNewsUrl: {
      type: String,
      required: true,
    },
    latestNewsUrl: {
      type: String,
      required: true,
    },
    showSource: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NewsFeatured,
    NewsList,
  },
  mounted() {
    this.getNews();
  },
  methods: {
    async getNews() {
      let featuredPostID = "";
      
      // Determine which story is featured
      this.axios
        .get(`${this.featuredNewsUrl}`)
        .then((res) => {
          // console.log(res.data);
          featuredPostID = res.data.featured_post.ID;
          
          // Get the featured news story based on the ID
          this.axios
            .get(`https://news.uga.edu/wp-json/wp/v2/posts/${featuredPostID}?_embed=wp:featuredmedia&_fields=_links.wp:featuredmedia,_embedded,link,title,date,_links`)
            .then((res) => {
              this.featuredPost.push(res.data);
            })
            .catch((error) => {
              console.error(error);
              this.errored = true;
            });

          // Get the secondary news stories and exclude the featured story
          this.axios
            .get(`${this.latestNewsUrl}&exclude=${featuredPostID}`)
            .then((res) => {
              this.secondaryPosts = res.data;
            })
            .catch((error) => {
              console.error(error);
              this.errored = true;
            });
            
        })
        .catch((error) => {
          console.error(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.news {
  max-width: 100%;
  // padding: 3rem;
  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  &__title-header,
  &__title-link {
    @include heading-base;
    font-size: $header-sm;
    @include media-breakpoint-up(md) {
      font-size: $header-md;
    }
  }
  &__title-link {
    color: $red;
  }
}
</style>
