<template>
  <div style="position: relative;">
    <div class="audience-panel__nav">
      <a
        v-for="(audience, i) in audiences"
        :key="i"
        @click="emitClick(i)"
        :class="isActive(i)"
        >{{ audience.name }}</a
      >
    </div>
    <div id="gradient"></div>
  </div>
</template>
<script>
export default {
  name: 'AudienceNav',
  data() {
    return { current: 0 };
  },
  props: {
    audiences: { type: Array, required: true },
  },

  methods: {
    isActive(i) {
      if (i == this.current) {
        return 'audience-panel__nav-link ' + 'current';
      } else {
        return 'audience-panel__nav-link';
      }
    },
    emitClick(i) {
      this.current = i;
      this.$emit('audience-select', i);
    },
  },
};
</script>
<style scoped lang="scss">
#gradient {
  position: absolute;
  top: 0;
  right: -10px;
  background: -webkit-linear-gradient(
    left,
    rgba(85, 79, 70, 0),
    rgba(85, 79, 70, 1)
  );

  height: 100%;
  width: 40px;
}
.audience-panel__nav {
  display: flex;
  position: relative;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  white-space: nowrap;
  margin-bottom: 2rem;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  &-link {
    cursor: pointer;
    padding-bottom: 0.5rem;
    font-family: $font-family-serif;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 1.4375rem;
    letter-spacing: 0em;
    text-align: left;
    color: $white;
    text-transform: capitalize;
    border-bottom: 4px solid transparent;
    &:hover,
    &:active,
    &:visited {
      color: $white !important;
    }
    &.current {
      border-color: $white;
    }
  }

  &-link:not(:last-child) {
    margin-right: 2rem;
  }
}
@include media-breakpoint-up(md) {
  #gradient {
    visibility: hidden;
  }
}
@include media-breakpoint-up(lg) {
  .audience-panel__nav-link {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}
</style>
