<template>
  <div class="row">
    <div class="col-lg-7">
      <div class="audience-panel__content">
        <h2 class="audience-panel__content__title">
          Resources for <span>{{ title }}</span>
        </h2>

        <div class="audience-panel__content__container">
          <a
            v-for="link in links"
            :key="link.id"
            class="audience-panel__content__item"
            :href="link.url"
            >{{ link.title }}</a
          >
        </div>
        <div class="audience-panel__content__more">
          <a
            v-if="$filters.isExternal(moreLink)"
            class="btn btn-outline-white audience-panel__content__more-link"
            :href="moreLink"
            >More Resources<span class="sr-only"> for {{ title }}</span></a
          >
          <router-link
            v-else
            class="btn btn-outline-white audience-panel__content__more-link"
            :to="moreLink"
            >More Resources<span class="visually-hidden">
              for {{ title }}</span
            ></router-link
          >
        </div>
      </div>
    </div>
    <div class="col-lg-5" id="center-photo">
      <div class="large-shield-wrapper">
        <div
          class="large-shield"
          :style="{
            backgroundImage: `url(${require('@/assets/img/' + image)})`,
          }"
          role="img"
          alt=""
        ></div>
      </div>
      <svg height="0" width="0">
        <defs>
          <clipPath clipPathUnits="objectBoundingBox" id="shieldmask">
            <path
              class="cls-1"
              d="M1,0 v0.733 c0,0.14,-0.254,0.238,-0.5,0.267 c-0.246,-0.029,-0.5,-0.127,-0.5,-0.267 v-0.733"
            ></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>
<script>
// import Btn from '@/components/Btn.vue';
export default {
  name: "AudienceResources",
  components: {
    // Btn,
  },
  props: {
    title: { type: String, required: true },
    links: { type: Array, required: true },
    image: { type: String, required: true },
    moreLink: { type: String, required: true },
  },
  methods: {},
  computed: {},
};
</script>
<style scoped lang="scss">
.large-shield {
  max-width: 385px;
  height: 500px;
  background-size: cover;
  clip-path: url(#shieldmask);
  margin: 0 auto;
  background-position: center;
  transition: all 0.1s ease-in-out;
  &-wrapper {
    filter: drop-shadow(8px 8px 12px rgba(0, 0, 0, 0.4));
  }
}

.audience-panel__content {
  color: $white;
  &__container {
    display: grid;
    column-gap: 2rem;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__title {
    @include heading-base;
    font-size: 2rem;
    line-height: 1;
    text-align: left;
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(lg) {
      font-size: 3rem;
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
  }
  &__more {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    &-link {
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include heading-base;
    font-size: 1.25rem;
    line-height: 1.2;
    color: $white;
    text-decoration: none;
    border-bottom: 1px solid fade-out($white, 0.55);
    padding: 1rem 0;

    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
      padding: 1rem 0;
    }
    &:after {
      content: "";
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-chevron-right' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
      background-size: 1.5rem;
      background-repeat: no-repeat;
      background-position: center right;
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: baseline;
      margin-left: 0.5rem;
      opacity: 0.2;
      transition: opacity 100ms ease-in;
    }
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $white;
      outline: none;
      &:after {
        opacity: 0.75;
      }
    }
  }
}
</style>
