<template>
  <li class="newslist-item">
    <a :href="story.link">
      <h2 class="newslist-item__title">
        <span
          class="newslist-item__title-link"
          v-html="story.title.rendered"
        ></span>
      </h2>
    </a>
    <div class="newslist-item__details">
      <div class="newslist-item__details__date">
        {{ getDate(story.date) }}
      </div>
      <a
        v-if="showSource"
        :href="hostnameFull(story.link)"
        class="newslist-item__details__link"
      >
        {{ hostname(story.link) }}
      </a>
    </div>
  </li>
</template>

<script>
import moment from 'moment';

export default {
  name: 'NewsItem',
  props: {
    story: {
      type: Object,
      required: true,
    },
    showSource: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getDate(date) {
      return moment(date).format('MMMM D, YYYY');
    },
    hostname(href) {
      let l = new URL(href);
      // console.log(l.hostname);
      return l.hostname;
    },
    hostnameFull(href) {
      return 'https://' + this.hostname(href);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.newslist-item {
  padding: 1rem 0;
  border-top: 1px solid $gray-400;
  &__title {
    font-family: $font-family-serif;
    font-size: $text-lg;
    font-weight: $font-weight-bold;
    &-link {
      color: $black;
    }
  }
  &__details {
    display: flex;
    font-family: $font-family-sans-serif;
    &__date {
      color: $light-grey;
      margin-right: 0.75rem;
    }
    &__link {
      color: $red;
    }
  }
}
@include media-breakpoint-up(lg) {
  .newslist {
    &-item {
      padding: 2rem 0;
      &:first-of-type {
        border-top: none;
        padding-top: 0;
      }
      &__title {
        font-family: $font-family-serif;
        font-size: $text-xl;
        line-height: 1.5;
      }
      &__details {
        &__date,
        &__link {
          font-size: $text-md;
          font-family: $font-family-sans-serif;
        }
      }
    }
  }
}
</style>
