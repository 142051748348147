<template>
  <div id="audience-panel">
    <AudienceNav :audiences="audiences" @audience-select="updateCurrent" />
    <AudienceResources
      v-if="current != null"
      :title="audiences[current].nameLong ? audiences[current].nameLong : audiences[current].name"
      :links="audiences[current].links"
      :image="audiences[current].image"
      :moreLink="audiences[current].moreLink"
    />
  </div>
</template>
<script>
import AudienceNav from '@/components/AudienceNav.vue';
import AudienceResources from '@/components/AudienceResources.vue';

export default {
  name: 'AudiencePanel',
  data() {
    return { current: null };
  },
  props: {
    audiences: { type: Array, required: true },
  },
  components: {
    AudienceNav,
    AudienceResources,
  },
  mounted() {
    this.setUp();
  },
  methods: {
    setUp() {
      this.current = 0;
    },
    updateCurrent(data) {
      this.current = data;
    },
  },
};
</script>
<style scoped lang="scss"></style>
