<template>
  <div class="image-grid" v-if="size === '3x3'">
    <div
      v-for="(image, i) in images"
      :key="image.src"
      :class="`image-grid__image-${i + 1}`"
    >
      <img
        v-if="!image.video"
        :class="getClass(image)"
        :src="require(`@/assets/img/${image.src}`)"
        loading="lazy"
      />
      <VideoBackground
        v-else
        :src="require(`@/assets/${image.src}`)"
        :class="getClass(image)"
      ></VideoBackground>
    </div>
    <Figure
      v-for="(figure, i) in figures"
      :key="figure.number"
      :class="`image-grid__fig-${i + 1}`"
      :prefix="figure.prefix"
      :endAmount="figure.endAmount"
      :subheading="figure.subheading"
      :backgroundColor="figure.backgroundColor"
      :textColor="figure.textColor"
      :textured="figure.textured"
    >
    </Figure>
  </div>

  <div class="image-grid--2x2" v-else>
    <div
      v-for="(image, i) in images"
      :key="image.src"
      :class="`image-grid__image-${i + 1}`"
    >
      <img
        v-if="!image.video"
        :class="getClass(image)"
        :src="require(`@/assets/img/${image.src}`)"
        loading="lazy"
      />
      <VideoBackground
        v-else
        :src="require(`@/assets/${image.src}`)"
        :class="getClass(image)"
      ></VideoBackground>
    </div>
    <Figure
      v-for="(figure, i) in figures"
      :key="figure.number"
      :class="`image-grid__fig-${i + 1}`"
      :prefix="figure.prefix"
      :endAmount="figure.number"
      :subheading="figure.subheading"
      :backgroundColor="figure.backgroundColor"
      :textColor="figure.textColor"
      :textured="figure.textured"
    >
    </Figure>
  </div>
</template>
<script>
import Figure from "@/components/Figure.vue";
import VideoBackground from "@/components/VideoBackground";

export default {
  name: "ImageGrid",
  props: {
    /**
     * Array of Image Objects
     * Each object requries:
     * 1. img - name of the image (String)
     * 2. position - images position in the grid (Int, 1-4)
     * 3. focus - sets object-position property on mobile (String, 'top' or 'bottom')
     */
    images: Array,
    /**
     * Array of Figure Objects
     * Each object requries:
     * 1. heading - String
     * 2. subheading - String
     * 3. color - String
     * 4. textured - Boolean
     */
    figures: Array,
    size: {
      type: String,
      required: true,
      validator: (propValue) => {
        return ["3x3", "2x2"].includes(propValue);
      },
    },
  },
  components: {
    Figure,
    VideoBackground,
  },
  methods: {
    /**
     * Generates class name based on position and focus attributes
     */
    getClass(img) {
      let cls = ["image-grid__image"];
      if (img.focus === "top") {
        cls.push("focus-top");
      } else if (img.focus === "bottom") {
        cls.push("focus-bottom");
      }
      return cls;
    },
  },
};
</script>
<style scoped lang="scss">
.focus-bottom {
  object-position: bottom !important;
}
.focus-top {
  object-position: top !important;
}
.image-grid {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
  &--2x2 {
    display: grid;
    grid-template-columns: 50% 50%;
    & > .image-grid__image {
      &-1 {
        grid-row: 1;
        grid-column: 2;
      }
      &-2 {
        grid-row: 2;
        grid-column: 1;
        object-fit: cover;
      }
    }
  }

  &__image {
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    &-1 {
      grid-row: 2;
      grid-column: 1;
    }
    &-2 {
      grid-row: 2;
      grid-column: 2;
    }
    &-3 {
      grid-column: 1;
      grid-row: 4;
    }
    &-4 {
      grid-column: 2;
      grid-row: 4;
    }
  }
  &__fig {
    &-1 {
      grid-column: span 2;
      grid-row: 1;
    }
    &-2 {
      grid-column: span 2;
      grid-row: 3;
    }
    &-3 {
      grid-column: span 2;
      grid-row: 5;
    }
  }
}
@include media-breakpoint-up(lg) {
  .image-grid {
    margin: 0 auto;
    grid-template-columns: repeat(3, 33.333%);

    &__image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      &-1 {
        grid-row: 1;
        grid-column: 2;
      }
      &-2 {
        grid-row: 1 / 3;
        grid-column: 3;
      }
      &-3 {
        grid-column: 1;
        grid-row: 2 / 4;
      }
      &-4 {
        grid-column: 2;
        grid-row: 3;
      }
    }
    &__fig {
      height: 100%;
      &-1 {
        grid-column: 1;
        grid-row: 1;
      }
      &-2 {
        grid-column: 2;
        grid-row: 2;
      }
      &-3 {
        grid-column: 3;
        grid-row: 3;
      }
    }
  }
}
</style>
