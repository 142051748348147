<template>
  <div class="featured-news">
    <a :href="story.link">
      <img
        :src="featuredImageSrc"
        class="featured-news__image w-100"
        loading="lazy"
        :alt="featuredImageAlt"
      />
      <h2 class="featured-news__title">
        <span
          class="featured-news__title-link"
          v-html="story.title.rendered"
        ></span>
      </h2>
    </a>
    <div class="featured-news__details">
      <div class="featured-news__details__date">
        {{ getDate(story.date) }}
      </div>
      <a
        v-if="showSource"
        :href="hostnameFull(story.link)"
        class="featured-news__details__link"
        >{{ hostname(story.link) }}</a
      >
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'NewsFeatured',
  props: {
    story: {
      type: Object,
      required: true,
    },
    showSource: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getDate(date) {
      return moment(date).format('MMMM D, YYYY');
    },
    hostname(href) {
      let l = new URL(href);

      return l.hostname;
    },
    hostnameFull(href) {
      return 'https://' + this.hostname(href);
    },
  },
  computed: {
    featuredImageSrc() {
      // TODO: custom image size with forced horizontal crop
      let imageSizes = this.story._embedded['wp:featuredmedia']['0']
        .media_details.sizes;
      let featuredImage = null;
      if ('large' in imageSizes) {
        featuredImage = imageSizes.large.source_url;
      } else if ('full' in imageSizes) {
        featuredImage = imageSizes.full.source_url;
      } else {
        featuredImage = require('@/assets/img/homepage/35501-081_edit.jpg');
      }
      return featuredImage;
    },
    featuredImageAlt() {
      let alt = this.story._embedded['wp:featuredmedia']['0'].alt_text;
      return alt;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.featured-news {
  &__title {
    font-family: $font-family-serif;
    font-size: $text-lg;
    font-weight: $font-weight-bold;
    &-link {
      color: $black;
    }
  }
  &__image {
    margin-bottom: 1.5rem;
  }
  &__details {
    display: flex;
    font-family: $font-family-sans-serif;
    &__date {
      color: $light-grey;
      margin-right: 0.75rem;
    }
    &__link {
      color: $red;
    }
  }
}
@include media-breakpoint-up(lg) {
  .featured-news {
    &__title {
      font-size: $text-xl;
      font-family: $font-family-serif;
      line-height: 1.5;
    }
    &__details {
      &__date,
      &__link {
        font-size: $text-md;
        font-family: $font-family-sans-serif;
      }
    }
  }
}
</style>
